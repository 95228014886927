<template>
  <div class="navList">
    <header>
      <img src="@/assets/img/login/logo.png" alt="" />
      <span class="ischosed" @click="gotoOther">推荐</span>
      <span>|</span>
      <span @click="choseOther(7)">询价公告</span>
      <span>|</span>
      <span @click="choseOther(1)">招标公告</span>
      <span>|</span>
      <span @click="choseOther(2)">资格预审公告</span>
      <span>|</span>
      <span @click="choseOther(6)">中标公告</span>
      <span>|</span>
      <span @click="choseOther(5)">变更公告</span>
    </header>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "navList",
  methods: {
    choseOther(val) {
      this.$router.push({ path: "/messageList", query: { type: val } });
    },
  },
};
</script>

<style lang="scss" scoped>
.navList {
  background-color: #fff;
}
.ischosed {
  color: red;
}
header {
  width: 1200px;
  margin: 0 auto;
  height: 75px;
  display: flex;
  font-size: 16px;
  img {
    width: 200px;
    height: 42px;
    margin-top: 15px;
    margin-right: 36px;
  }
  span:nth-of-type(odd) {
    display: inline-block;
    text-align: center;
    cursor: pointer;
    &:hover {
      color: red;
    }
  }
  span:nth-of-type(even) {
    display: inline-block;
    width: 40px;
    text-align: center;
  }
  span {
    margin-top: 29px;
    user-select: none;
  }
}
</style>