<template>
  <div>
    <navList />
    <div>





    </div>
    <div class="footers">
      <footers />
    </div>
  </div>
</template>

<script>
import footers from "@/components/footers.vue";
import navList from "./navList.vue";
export default {
  components: {
    navList,
    footers,
  },
};
</script>

<style lang="scss" scoped>
.footers {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>